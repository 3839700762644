import React from 'react';

import Index, { IndexProps } from 'components/layout';
import SEO from 'components/seo';
import PageNotFound from 'components/PageNotFound';

const NotFoundPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="notFound" />
    <PageNotFound />
  </Index>
);

export default NotFoundPage;
