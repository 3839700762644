import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage } from 'react-intl';
import Link from 'components/UI/Link';
import { isMobileOS } from 'helpers/utils/mobile';
import GeneralSettings from 'helpers/constants/settings';

import cmnStyles from 'styles/pages.module.scss';
import styles from './PageNotFound.module.scss';

const PageNotFound: React.FC = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const [isMobile, setMobileOS] = useState(false);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setMobileOS(isMobileOS());
  }, []);

  const colHeader = `${cmnStyles.alignSectionVerticallyCenter}`;
  const {
    imageDesk,
    imageMobile,
  } = useStaticQuery(
    graphql`
        query {
          imageDesk: file(relativePath: { eq: "notfound/bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 492) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            },
            imageMobile: file(relativePath: { eq: "notfound/bg-mobile.png" }) {
              childImageSharp {
                  fluid(maxWidth: 238) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          }
        }
    `,
  );
  const DesktopBg = (<GatsbyImage fluid={imageDesk.childImageSharp.fluid} />);
  const MobileBg = (<GatsbyImage fluid={imageMobile.childImageSharp.fluid} />);

  const Background = screenWidth > GeneralSettings.MOBILE_VIEW.NOT_FOUND || isMobile
    ? DesktopBg : MobileBg;
  return (
    <Container className={styles.wrapper}>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 6, order: 1 }}
          xl={{ span: 6, order: 1 }}
          className={colHeader}
        >
          <h1 className={`${cmnStyles.articleTitle} ${styles.title}`}>
            <span>404:</span>
            <br />
            <FormattedMessage id="components.pageNotFound.title" />
            <span className={styles.on}>ON</span>
            <span>OFF </span>
            <br />
            <FormattedMessage id="components.pageNotFound.titleEnd" />
          </h1>
          <p className={styles.description}>
            <FormattedMessage
              id="components.pageNotFound.description"
              values={{
                a: (...chunks: string[]): JSX.Element => (
                  <Link to="/" className={styles.link}>
                    {chunks}
                  </Link>
                ),
              }}
            />
          </p>
        </Col>
        <Col
          xs={{ span: 7, offset: 5, order: 1 }}
          sm={{ span: 7, offset: 5, order: 1 }}
          md={{ span: 7, offset: 5, order: 1 }}
          lg={{ span: 6, offset: 0, order: 1 }}
          xl={{ span: 6, offset: 0, order: 1 }}
          className={styles.bg}
        >
          {Background}
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
